<template>
  <div class="pb-2">
    <h1 class="text-left font-medium-5 font-weight-bolder mb-2">
      Pricing Rules
    </h1>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME_PRICE"
      :table-columns="tableColumnsPrice"
      :create-page-url="{ name: 'settings-catalog-price-create' }"
      back-to-list-path="settings-catalog-price-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME_PRICE}/trashList`,
      }"
      @onChoose="onChoose"
    >
      <template #cell(actions)="{ data }">
        <div
          class="d-flex justify-content-end"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LTrashIcon"
            :class="'lightIcon cursor-pointer border-dotted featherIcon'"
            size="16"
            :title="$t('Delete')"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #cell(status)="data">
        {{ data.data.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(default)="{ data }">
        <div
          v-if="data.is_active"
          class="ml-2"
        >
          <span
            :id="data.id"
            class="cursor-pointer d-flex align-items-center position-relative"
            style="width: max-content; height: 20px; opacity: 0.9 "
            :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
            @click.once="setDefault(data)"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="ml-2 custom-control-group"
            >
              <b-form-radio
                v-model="data.is_default"
                class="mt-1"
                :aria-describedby="ariaDescribedby"
                :name="`some-radios-${data.name}`"
                :value="true"
                size="lg"
              />
            </b-form-group>
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px; left: 15px; top: 4px"
              class="ml-1 position-absolute"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </span>
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-cycle
import { title } from '@core/utils/filter'
import {
  BFormRadio, BFormGroup,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '../pricingConfig'

export default {
  name: 'PricingRules',
  components: {
    LTable,
    BFormGroup,
    BFormRadio,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
      draggedItem: null,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-pricing-rules-list')
  },
  inject: ['pricingRulesModuleName'],
  methods: {
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME_PRICE}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      const { item } = data

      this.confirmNotification(this, item, `${this.pricingRulesModuleName}/del`)
        .then(() => {
          this.refetchData()
        })
    },
    onChoose(item) {
      const { moved: { element: { id }, newIndex } } = item

      this.sendNotification(
        this, { pricing_rule: id, position: newIndex },
        `${this.MODULE_NAME_PRICE}/setPosition`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    setDefault(data) {
      console.log(data)
      const { id } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME_PRICE}/setDefault`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    update(data) {
      this.$router.push({
        name: 'settings-catalog-pricing-rules-update',
        params: { id: data.id },
      })
    },
    duplicate(data) {
      this.$refs.lTableRef.isBusy = true
      this.$store.dispatch(`${this.MODULE_NAME_PRICE}/duplicate`, data).then(() => {
        this.refetchData()
      }).catch(() => {
        this.$refs.lTableRef.isBusy = false
      })
    },
  },
  setup() {
    const MODULE_NAME_PRICE = 'pricing-rules'
    const {
      tableColumnsPrice,
    } = tableConfig()
    return {
      tableColumnsPrice,
      MODULE_NAME_PRICE,
      title,
    }
  },
}
</script>
