import {
  SELECT_INPUT, TEXT_INPUT, CHECKBOX_LEF, FLAT_PICKER, RADIO_GROUP, L_TAGS_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  // Table Handlers
  const tableColumnsPrice = [
    { key: 'actions', label: '', thStyle: { width: '100px' } },
    { key: 'pricing_rule_name', label: 'Name', thStyle: { width: '300px' } },
    { key: 'status', label: 'Status', thStyle: { width: '150px' } },
    { key: 'default', label: 'Default' },
  ]
  const tableColumnsPriceTiers = [
    { key: 'actions', label: '', thStyle: { width: '100px' } },
    { key: 'label', label: 'Name', thStyle: { width: '300px' } },
    { key: 'status', label: 'Status', thStyle: { width: '150px' } },
    { key: 'default', label: 'Default' },
  ]
  const tableColumnsDiscounts = [
    { key: 'actions', label: '', thStyle: { width: '100px' } },
    { key: 'name', label: 'Name', thStyle: { width: '300px' } },
    { key: 'time_period', label: 'Time Period', thStyle: { width: '200px', textAlign: 'center' } },
    { key: 'rate', label: 'Discount Rate (%)', thStyle: { width: '200px', textAlign: 'center' } },
    { key: 'position', label: 'Priority', thStyle: { width: '150px', textAlign: 'center' } },
  ]
  const tableColumnsDiscountProducts = [
    {
      key: 'action', label: '', thStyle: { width: '10%', padding: '5px 8px' }, tBodyThStyle: { width: '80%', padding: '5px 8px' },
    },
    { key: 'sku', label: 'SKU', thStyle: { width: '12%', padding: '5px 8px' } },
    { key: 'name', label: 'Name', thStyle: { width: '30%', padding: '5px 8px' } },
    { key: 'category', label: 'Category', thStyle: { width: '25%', padding: '5px 8px' } },
  ]

  const autoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'detail.model',
      label: 'Model',
    },
    {
      key: 'categories',
      label: 'Category',
      getValue(value, data) {
        return data.categories.map(category => category.name).join(', ')
      },
    },
  ]

  const fields = {
    label: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    currency_id: {
      type: SELECT_INPUT,
      label: 'Currency',
      store: 'currencyList',
      rules: 'required',
      options: {
        label: 'iso_currency',
      },
    },
    product_pricing_rule: {
      type: SELECT_INPUT,
      label: 'Pricing Rule',
      store: 'catalogPricingRulesList',
      rules: 'required',
      options: {
        label: 'pricing_rule_name',
      },
    },
    type: {
      type: SELECT_INPUT,
      label: 'Type',
      store: 'priceTiersType',
      rules: 'required',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
    },
    is_required: {
      type: CHECKBOX_LEF,
      label: 'Required',
    },
  }
  const pricingFields = {
    pricing_rule_name: {
      type: TEXT_INPUT,
      label: 'Pricing Rule Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    currency_id: {
      type: SELECT_INPUT,
      label: 'Currency',
      store: 'currencyList',
      firstSelect: true,
      options: {
        label: 'iso_currency',
      },
    },
    pricing_by_month_id: {
      type: SELECT_INPUT,
      label: 'Pricing Month By',
      store: 'settings_pricing_rules_pricing_month_by',
      options: {
        label: 'value',
      },
    },
    base_price_selection_id: {
      type: SELECT_INPUT,
      label: 'Base Price Selection',
      store: 'settings_pricing_rules_base_price_selection',
      options: {
        label: 'value',
      },
    },
    prorate_week: {
      type: TEXT_INPUT,
      label: 'Week',
      rules: 'required',
      placeholder: ' ',
    },
    prorate_month_id: {
      type: SELECT_INPUT,
      label: 'Month',
      rules: 'required',
      store: 'settings_pricing_rules_prorate_month',
      options: {
        label: 'value',
      },
    },
    prorate_days: {
      type: TEXT_INPUT,
      label: '',
    },
    change_charge_end_time_return_id: {
      type: SELECT_INPUT,
      label: 'Change Charge End Time At Return',
      store: 'settings_pricing_rules_change_charge_end_time_return',
      options: {
        label: 'value',
      },
    },
    change_charge_start_time_ship_id: {
      type: SELECT_INPUT,
      label: 'Change Charge Start Time At Ship',
      store: 'settings_pricing_rules_change_charge_start_time_ship',
      options: {
        label: 'value',
      },
    },
    days_in_week: {
      type: TEXT_INPUT,
      label: 'Days In Week',
      placeholder: 'Type...',
    },
    days_in_month: {
      type: TEXT_INPUT,
      label: 'Days In Month',
      placeholder: 'Type...',
    },
    grace_period: {
      type: TEXT_INPUT,
      label: 'Grace Period',
      placeholder: 'Type...',
    },
    default_unit_id: {
      type: SELECT_INPUT,
      label: 'Default Unit',
      store: 'settings_pricing_rules_default_unit',
      options: {
        label: 'value',
      },
    },
    allowable_days: {
      type: TEXT_INPUT,
      label: 'Allowable Days',
      placeholder: 'Type...',
    },
    compute_total_cost_for_id: {
      type: SELECT_INPUT,
      label: 'Compute Total Cost For',
      store: 'settings_pricing_rules_compute_total_cost_for',
      options: {
        label: 'value',
      },
    },
    labor_cost_based_on_id: {
      type: SELECT_INPUT,
      label: 'Labor Cost Based On',
      store: 'settings_pricing_rules_labor_cost_based_on',
      options: {
        label: 'value',
      },
    },
    equip_cost_based_on_id: {
      type: SELECT_INPUT,
      label: 'Equip. Cost Based On',
      store: 'settings_pricing_rules_equip_cost_based_on',
      options: {
        label: 'value',
      },
    },
    misc_cost_based_on_id: {
      type: SELECT_INPUT,
      label: 'Misc. Cost Based On',
      store: 'settings_pricing_rules_misc_cost_based_on',
      options: {
        label: 'value',
      },
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    default_item_price_fill_order: {
      type: CHECKBOX_LEF,
      label: 'Default Items Price For FillOrder',
    },
    service_charge_first_invoice: {
      type: CHECKBOX_LEF,
      label: 'Service Charge On First Invoice',
    },
    show_cost_deleted_lines: {
      type: CHECKBOX_LEF,
      label: 'Show cost for Deleted lines',
    },
    auto_extend_charge_duration: {
      type: CHECKBOX_LEF,
      label: 'Auto Extend Charge Duration',
    },
    check_credit_limit_hold_orders: {
      type: CHECKBOX_LEF,
      label: 'Check Credit Limit For Hold Orders',
    },
    reparation_cost: {
      type: CHECKBOX_LEF,
      label: 'Reparation cost',
    },
    consumable_discounted_amount: {
      type: CHECKBOX_LEF,
      label: 'Consumable On Discounted Amount',
    },
    consumable_first_invoice: {
      type: CHECKBOX_LEF,
      label: 'Consumable On First Invoice',
    },
    non_chargeable_sunday: {
      type: CHECKBOX_LEF,
      label: 'Sunday',
    },
    non_chargeable_monday: {
      type: CHECKBOX_LEF,
      label: 'Monday',
    },
    non_chargeable_tuesday: {
      type: CHECKBOX_LEF,
      label: 'Tuesday',
    },
    non_chargeable_wednesday: {
      type: CHECKBOX_LEF,
      label: 'Wednesday',
    },
    non_chargeable_thursday: {
      type: CHECKBOX_LEF,
      label: 'Thursday',
    },
    non_chargeable_friday: {
      type: CHECKBOX_LEF,
      label: 'Friday',
    },
    non_chargeable_saturday: {
      type: CHECKBOX_LEF,
      label: 'Saturday',
    },
    po_price_items_parts_id: {
      type: SELECT_INPUT,
      label: 'For Items & Parts',
      store: 'settings_pricing_rules_po_price_items_parts',
      options: {
        label: 'value',
      },
    },
    po_price_misc_id: {
      type: SELECT_INPUT,
      label: 'For Misc PO',
      store: 'settings_pricing_rules_po_price_misc',
      options: {
        label: 'value',
      },
    },
    po_price_subrent_id: {
      type: SELECT_INPUT,
      label: 'For Subrent PO',
      store: 'settings_pricing_rules_po_price_subrent',
      options: {
        label: 'value',
      },
    },
    po_price_inventory_id: {
      type: SELECT_INPUT,
      label: 'For Inventory PO',
      store: 'settings_pricing_rules_po_price_inventory',
      options: {
        label: 'value',
      },
    },

  }

  const discountsFields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
    },
    rate: {
      type: TEXT_INPUT,
      label: 'Discount Rate (%)',
      rules: 'numeric|required',
    },
    start: {
      type: FLAT_PICKER,
      label: 'Discount Start',
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    end: {
      type: FLAT_PICKER,
      label: 'Discount End',
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    // types: {
    //   type: RADIO_GROUP,
    //   label: 'Brands',
    //   rules: 'required',
    //   // list: store.state.listModule.discountTypesList,
    //   // RADIO_GROUP
    // },
    tags: {
      type: L_TAGS_INPUT,
      label: 'Tags',
      store: 'tagsList',
    },
    brands: {
      type: L_TAGS_INPUT,
      label: 'Brands',
      store: 'brandsList',
    },
    categories: {
      type: L_TAGS_INPUT,
      label: 'Categories',
      store: 'categoriesList',
    },
  }
  const costingDefault = {
    catalog_price_costing_default: {
      type: TEXT_INPUT,
      rules: 'numeric',
      label: 'Require deposit over replacement cost of:',
      placeholder: 'Type...',
    },
  }
  return {
    tableColumnsPriceTiers,
    tableColumnsPrice,
    tableColumnsDiscounts,
    tableColumnsDiscountProducts,
    autoSuggestTableColumns,
    fields,
    costingDefault,
    pricingFields,
    discountsFields,
  }
}
